/*  ============
    Fonts
    ============ */

/* audiowide-regular - latin */
// https://google-webfonts-helper.herokuapp.com/fonts/audiowide?subsets=latin
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../assets/fonts/audiowide-v16-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/audiowide-v16-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* nunito-regular - latin */
// https://google-webfonts-helper.herokuapp.com/fonts/nunito?subsets=latin
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../assets/fonts/nunito-v25-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/nunito-v25-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
  --title-font: 'Audiowide', cursive;
  --text-font: 'Nunito', sans-serif;
}

/*  ============
    Global Styles
    ============ */
*,
*::backdrop,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: var(--text-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #ffffff;
}

html {
  scroll-behavior: smooth;
}
/*  ============
    Typography
    ============ */
h1,
h2,
h4 {
  font-family: var(--title-font);
}

h1 {
  font-size: 1.8rem;
  line-height: 1.79;
}

h2 {
  font-size: 1.6rem;
  line-height: 1.79;
}

h3,
p,
a {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5em;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
}

:root {
  --min-font-size: 0.875rem;
}

a {
  color: #ffffff;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-decoration: none;
}

span {
  white-space: nowrap;
}

.label {
  color: rgb(198, 12, 56);
}
/*  ============
    Layout
    ============ */
/*
    Breakpoints
      mobile: width <640
      tablet: width <800
      small-desktop: width <960
      desktop: width >960
    */

:root {
  font-size: 10px;
}

@media only screen and (min-width: 640px) {
  :root {
    font-size: 1.4vw; /* calc(16px * 0.7); */
  }
  h3,
  p,
  a {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 800px) {
  :root {
    font-size: 1.416vw; /* calc(16px * 0.85); */
  }
  h3,
  p,
  a {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 960px) {
  :root {
    font-size: 16px;
  }
  h3,
  p,
  a {
    font-size: 1rem;
  }
}

/*  ============
    Components
    ============ */

button {
  font-family: var(--text-font);
  font-size: var(--min-font-size);
}

::-moz-selection {
  color: white;
  background: rgb(198, 12, 56);
}

::selection {
  color: white;
  background: rgb(198, 12, 56);
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none;
  }
}
